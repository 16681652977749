import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueLoading from 'vue-loading-template'
Vue.use(VueLoading, /** options **/)
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);




Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')